import React, { Component, useEffect, useRef } from 'react';
import { Tree } from 'react-arborist';
import { ListGroup, ListGroupItem, FormGroup, Form, Label, Input, Col, Button } from 'reactstrap';
import chevronDown from '../assets/chevron-down.svg';
import chevronRight from '../assets/chevron-right.svg';
import filterIcon from '../assets/funnel-fill.svg';
import reloadIcon from '../assets/arrow-clockwise.svg';
import exclamationIcon from '../assets/exclamation-fill.svg';
import locIcon from '../assets/location-pin.svg';
import pitIcon from '../assets/pit.png';
import benchIcon from '../assets/bench.png';
import shotIcon from '../assets/shot.png';
import planIcon from '../assets/plan.svg';
import holeIcon from '../assets/hole.svg';

function contextMenu(e) {
    e.preventDefault();
    console.log("Right click", e.pageX, e.pageY);
    this.setState({ showContext: true, contextX: e.pageX, contextY: e.pageY, contextID: e.target.id });

    const handleClick = () => {
        this.setState({ showContext: false, contextID: null });
        document.removeEventListener("click", handleClick);
    }
    document.addEventListener("click", handleClick);
}

function Node({ node, style, dragHandle }) {
    var selstyle = { ...style, cursor: 'pointer' };

    if (node.isSelected) {
        selstyle = { ...selstyle, fontWeight: 'bold' };
    }

    if (node.data?.id?.includes("reviewRequired")) {
        selstyle = { ...selstyle, color: '#EC6519' };
    }

    var icon = <></>;
    if (node.isOpen) {
        icon = <img style={{ paddingRight: '2px', paddingBottom: '2px' }} src={chevronDown} />
    } else if (!node.isLeaf) {
        icon = <img style={{ paddingRight: '2px', paddingBottom: '2px' }} src={chevronRight} />
    }

    var type = node.data?.id?.split("-")[0];
    if (type == "loc") {
        icon = <>{icon} <img style={{ paddingRight: '2px', paddingBottom: '2px' }} src={locIcon} /></>
    } else if (type == "subloc") {
        icon = <>{icon} <img style={{ paddingRight: '2px', paddingBottom: '2px' }} src={pitIcon} /></>
    } else if (type == "seq") {
        icon = <>{icon} <img style={{ paddingRight: '2px', paddingBottom: '2px' }} src={benchIcon} /></>
    } else if (type == "subseq") {
        icon = <>{icon} <img style={{ paddingRight: '2px', paddingBottom: '2px' }} src={shotIcon} /></>
    } else if (type == "plan") {
        icon = <>{icon} <img style={{ paddingRight: '2px', paddingBottom: '2px' }} src={planIcon} /></>
    } else if (type == "dispatch") {
        icon = <>{icon} <img style={{ height: '20px', width: '20px', paddingRight: '2px', paddingBottom: '2px' }} src={holeIcon} /></>
    }

    return (
        <div id={node.id} style={selstyle} onContextMenu={contextMenu.bind(this)}>
            {icon}{node.data.name}{node.data?.id?.includes("reviewRequired") && <img style={{ height: '13px', width: '13px', marginLeft: '5px', marginBottom: '3px' }} src={exclamationIcon} />}
        </div>
    );
}

export class TreeView extends Component {
    

    constructor(props) {
        super(props);

        this.state = {
            showContext: false,
            contextX: 0,
            contextY: 0,
            contextID: null,
            filter: null,
            filterShow: false
        };
    }

    toggleConfigModal(option) {
        this.setState({ showContext: false, contextID: null });
        this.props.toggleConfig(option);
    }

    togglePlanModal() {
        this.setState({ showContext: false, contextID: null });
        this.props.togglePlan();
    }

    nodeSelect(node) {
        if (node.length > 0) {
            if (node[0].data != null) {
                //console.log(node[0].data.id);
                var type = node[0].data.id.split("-")[0];
                var id = node[0].data.id.split("-")[1]
                if (type == "subloc") {
                    this.props.setSelectedSubLocation(id);
                } else if (type == "seq") {
                    this.props.setSelectedSequence(id);
                } else if (type == "subseq") {
                    this.props.setSelectedSubSequence(id);
                } else if (type == "plan") {
                    this.props.setSelectedPlan(id);
                } else if (type == "dispatch") {
                    this.props.setSelectedDispatch(id);
                }
                
            }
            node[0].toggle();
        }
    }

    contextMenuElement() {
        if (this.state.contextID != null) {
            var type = this.state.contextID.split("-")[0];
            var id = this.state.contextID.split("-")[1];

            var elements = [];
            if (type == 'loc') {
                elements.push(<ListGroupItem action onClick={() => this.toggleConfigModal("subloc")}>Add New Pit</ListGroupItem>);
            } else if (type == 'subloc') {
                elements.push(<ListGroupItem action onClick={() => this.toggleConfigModal("subloc")}>Add New Pit</ListGroupItem>);
                elements.push(<ListGroupItem action onClick={() => this.toggleConfigModal("seq")}>Add New RL</ListGroupItem>);
            } else if (type == 'seq') {
                elements.push(<ListGroupItem action onClick={() => this.toggleConfigModal("seq")}>Add New RL</ListGroupItem>);
                elements.push(<ListGroupItem action onClick={() => this.toggleConfigModal("subseq")}>Add New Shot</ListGroupItem>);
            } else if (type == 'subseq') {
                elements.push(<ListGroupItem action onClick={() => this.toggleConfigModal("subseq")}>Add New Shot</ListGroupItem>);
                elements.push(<ListGroupItem action onClick={() => this.togglePlanModal()}>Add New Plan</ListGroupItem>);
            } else if (type == 'plan') {
                elements.push(<ListGroupItem action onClick={() => this.togglePlanModal()}>Add New Plan</ListGroupItem>);
            }

            return (<div style={{ position: 'fixed', width: '150px', borderradius: '5px', top: this.state.contextY + "px", left: this.state.contextX + "px" }}><ListGroup>{elements}</ListGroup></div>);
        } else {
            return (<></>);
        }
    }

    handleFilterChange(e) {
        this.setState({ filter: e.target.value });
    }

    toggleFilter() {
        this.setState({ filterShow: !this.state.filterShow });
    }

    render() {
        const contextMenu = this.contextMenuElement();

        return (
            <>
                
                <Form style={{ paddingTop: "5px" }} id='sidebar-controls'>
                    <FormGroup row style={{ marginBottom: 0 }}>
                        <Col sm={6}>
                            <div style={{ fontWeight: 500, color: '#123241', textAlign: 'center', cursor: 'pointer' }} onClick={this.toggleFilter.bind(this)}><img src={filterIcon} style={{ marginBottom: '3px', paddingRight: '2px' }}/>Filter</div>
                        </Col>
                        <Col sm={6}>
                            <div style={{ fontWeight: 500, color: '#123241', textAlign: 'center', cursor: 'pointer' }} onClick={this.props.refreshData}><img src={reloadIcon} style={{ marginBottom: '3px', paddingRight: '2px'}}/>Refresh</div>
                        </Col>
                    </FormGroup>
                    {this.state.filterShow && <FormGroup row style={{ marginBottom: 0 }}>
                        <Col sm={12}>
                            <Input id="filter" name="filter" onChange={this.handleFilterChange.bind(this)} />
                        </Col>
                        </FormGroup>}
                </Form>
                <Tree width={350} data={this.props.treeData} disableDrag={true} disableDrop={true} onSelect={this.nodeSelect.bind(this)} searchTerm={this.state.filter} openByDefault={false} selection={this.props.selectedId}>
                    {Node.bind(this)}
                </Tree>

                { this.state.showContext && contextMenu }
            </>
        );
    }
}